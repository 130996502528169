import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { motion } from "framer-motion";

const Donate = () => {
    return (
        <>
            <div className="flex flex-col items-center justify-center lg:mt-24 w-full">
                <div className="bg-slate-800 p-5 rounded-lg text-center drop-shadow">
                <h1 className="lg:text-5xl text-4xl font-bold text-white mt-5 p-1">DONACIONES</h1>
                    <h2 className="lg:text-xl text-lg font-mono text-white mb-2">¿Querés colaborar con nosotros?
                    </h2>
                    <div className="p-1 text-left">
                        <p className="text-gray-400 text-s font-mono mt-2 mx-5">
                            En Atenea estamos en constante crecimiento y desarrollo. 
                            Este proyecto es posible gracias a la colaboración de la comunidad.
                            Si querés ayudarnos a mantener el proyecto, podés hacerlo a través de una donación.
                            
                        </p>
                        <p className="text-gray-400 text-s font-mono mt-2 mx-5">
                            Cualquier aporte es bienvenido y será destinado a mejorar la plataforma y la experiencia de usuario.
                        </p>
                        {/* Alias  */}
                    </div>
                    
                    <div className="flex flex-row items-center justify-center">
                        <div className="bg-slate-700 p-5 rounded-lg lg:w-1/3 w-full mt-5"
                        >
                            <div className="flex flex-row items-center justify-start">
                                <FontAwesomeIcon icon={faBuildingColumns} className="text-white text-2xl" />
                                <h1 className="text-xl font-bold text-white ml-2">
                                    Transferencia Bancaria</h1>
                            </div>
                            <div className="flex flex-col mt-5">
                                <h2 className="lg:text-lg text-sm font-mono text-left text-white">
                                    Alias:
                                </h2>
                                <div className="flex items-center my-2">
                                    <span className="bg-gray-800 font-semibold px-4 py-1 rounded-md lg:text-lg text-sm font-mono text-white ml-5">
                                        donaciones.lyt
                                    </span>
                                    <span className="ml-auto flex items-center">
                                        <FontAwesomeIcon icon={faCopy} className="text-white text-xl" />
                                    </span>
                                </div>
                                <h2 className="lg:text-lg text-sm font-mono text-left text-white">
                                    CBU:
                                </h2>
                                <div className="flex items-center my-2">
                                    <span className="bg-gray-800 font-semibold px-4 py-1 rounded-md lg:text-lg text-sm font-mono text-white ml-5">
                                        2590069120226635830160
                                    </span>
                                    <span className="ml-auto flex items-center">
                                        <FontAwesomeIcon icon={faCopy} className="text-white text-xl" />
                                    </span>
                                </div>
                            </div>


                        </div>
                    </div>     
                </div>
                
            </div>
        </>
    );
};

export default Donate;