import React from "react";

const SingUp = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [step, setStep] = React.useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    setName(e.target[0].value);
    // Censurar el email santorres@itba.edu.ar -> sa**********@itba.edu.ar SOLO LO QUE ESTA ANTES DEL @
    const user = e.target[1].value.split("@")[0];
    const domain = e.target[1].value.split("@")[1];
    const censoredEmail = user[0] + "*".repeat(user.length - 1) + "@" + domain;
    setEmail(censoredEmail);
    setStep(1);
  };

  const handleVerification = (e) => {
    e.preventDefault();
    setStep(2);
  };

  const verificationCodes = [
    "lema-del-sandwich",
    "1-2-bernstein",
    "lorem-ipsum-dolor",
    "regla-de-lhopital",
    "epsilon-delta-limite",
  ];

  return (
    <div className="flex lg:items-center justify-center h-screen bg-neutral-50">
      <div className="flex flex-col gap-5 lg:w-1/4">
        <div className="h-1/6 block lg:hidden"></div>
        <div className="flex items-center gap-3">
          <img src="./logo512.png" alt="logo" className="w-16 h-16" />
          <div className="flex flex-col">
            <h1 className="text-5xl text-pretty font-bold italic text-neutral-950">
              atenea
            </h1>
            <p className="text-neutral-500 text-md text-pretty">
              Todos tus <span className="text-primary italic">apuntes</span> en
              un solo lugar.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1"></div>
        {step === 0 && (
          <>
            <h1 className="ml-2 text-lg lg:text-xl text-pretty font-semibold text-neutral-950">
              Deja de perder tiempo buscando apuntes.
              <br />
            </h1>
            <form
              className="flex flex-col gap-10"
              action="#"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col gap-3">
                <input
                  type="text"
                  placeholder="¿Cómo te dicen?"
                  className="px-4 py-2 bg-inherit border border-neutral-300 rounded-lg shadow-sm focus:ring-1 focus:ring-neutral-950 focus:border-transparent focus:outline-none"
                />
                <input
                  type="text"
                  placeholder="jdoe@itba.edu.ar"
                  className="px-4 py-2 bg-inherit border border-neutral-300 rounded-lg shadow-sm focus:ring-1 focus:ring-neutral-950 focus:border-transparent focus:outline-none"
                />
              </div>
              <div className="flex flex-col gap-3">
                <button
                  type="submit"
                  className="bg-primary text-neutral-50 font-bold p-2 rounded-xl hover:bg-neutral-50 hover:text-primary hover:border-primary border-2 border-primary"
                >
                  Crear cuenta
                </button>
              </div>
            </form>
          </>
        )}
        {step === 1 && (
          <>
            <h1 className="ml-2 text-lg lg:text-xl text-pretty font-semibold text-neutral-950">
              ¡Bienvenido {name}!
              <br />
            </h1>
            <p className="text-neutral-500">
              Te hemos enviado un correo a {email} con un codigo de
              verificación.
            </p>
            <form className="flex flex-col gap-10" action="/">
              <div className="flex flex-col gap-3">
                <input
                  type="text"
                  placeholder={
                    verificationCodes[
                      Math.floor(Math.random() * verificationCodes.length)
                    ]
                  }
                  className="px-4 py-2 bg-inherit border border-neutral-300 rounded-lg shadow-sm focus:ring-1 focus:ring-neutral-950 focus:border-transparent focus:outline-none"
                />
              </div>
              <div className="flex flex-col gap-3">
                <button
                  type="submit"
                  onClick={() => setStep(2)}
                  className="bg-primary text-neutral-50 font-bold p-2 rounded-xl hover:bg-neutral-50 hover:text-primary hover:border-primary border-2 border-primary"
                >
                  Verificar
                </button>
              </div>
            </form>
          </>
        )}
        {step === 2 && (
          <>
            <h1 className="ml-2 text-lg lg:text-xl text-pretty font-semibold text-neutral-950">
              ¡Cuenta verificada!
              <br />
            </h1>
            <p className="text-neutral-500">
              Tu cuenta ha sido verificada exitosamente.
            </p>
            <div className="flex flex-col gap-3">
              <form className="flex flex-col gap-10" action="/">
                <div className="flex flex-col gap-3">
                  <input
                    type="password"
                    placeholder="•••••••••••"
                    className="px-4 py-2 bg-inherit border border-neutral-300 rounded-lg shadow-sm focus:ring-1 focus:ring-neutral-950 focus:border-transparent focus:outline-none"
                  />
                  <input
                    type="password"
                    placeholder="••••••••••• (again)"
                    className="px-4 py-2 bg-inherit border border-neutral-300 rounded-lg shadow-sm focus:ring-1 focus:ring-neutral-950 focus:border-transparent focus:outline-none"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-primary text-neutral-50 font-bold p-2 rounded-xl hover:bg-neutral-50 hover:text-primary hover:border-primary border-2 border-primary"
                >
                  Confirmar
                </button>
              </form>
            </div>
          </>
        )}
        <div className="flex gap-3 items-center justify-center">
          <p className="text-neutral-500">
            ¿Ya tienes una cuenta en{" "}
            <span className="italic font-medium">atenea</span>?
          </p>
          <a href="/login" className="text-primary hover:underline">
            Inicia sesión
          </a>
        </div>
      </div>
    </div>
  );
};

export default SingUp;
