import React from "react";

const Contact = () => {
    return (
        <>
            <div className="flex flex-col items-center justify-center lg:mt-24 w-full">
                <div className="bg-slate-800 p-5 rounded-lg text-center drop-shadow">
                <h1 className="lg:text-5xl text-4xl font-bold text-white mt-5 p-1">CONTACTO</h1>
                    <h2 className="lg:text-xl text-lg font-mono text-white mb-2">¿Tenés alguna duda o sugerencia?</h2>
                    <div className="p-1 text-left">
                        <p className="text-gray-400 text-s font-mono mt-2 mx-5">
                            Tanto para solicitar la publicación de tus apuntes, como para cualquier otra consulta, no dudes en contactarnos.
                        </p>
                        <p className="text-gray-400 text-s font-mono mt-2 mx-5">
                            Podés escribirnos a nuestro correo electrónico: 
                        </p>
                        <div className="flex flex-col items-center justify-center w-full">
                            <a className="block text-neutral-100 text-xl font-bold my-5 hover:text-orange-600 hover:bg-orange-100 p-2 rounded transition duration-300 ease-in-out"
                            href="mailto:contacto.atenea@lyt.ar">
                                    contacto.atenea@lyt.ar
                                </a>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Contact