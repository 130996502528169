import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from "react-router-dom";

const Account = () => {
    const navigate = useNavigate();
    const options_menu = [
        {
            icon: faBook,
            title: "Mis apuntes",
        },
        {
            icon: faThumbtack,
            title: "Materias pinneadas",
        },
        {   
            icon: faGear,
            title: "Configuración",
        }
    ];
    const cards_list = [
        {
            icon: faDollarSign,
            title: "DONACIONES",
            description: "¡Ayudanos a mantener el proyecto!",
            color: "bg-rose-700",
            onClick: () => navigate("/donate")
        },
        {
            icon: faEnvelope,
            title: "CONTACTO",
            description: "¿Tenés alguna duda o sugerencia?",
            color: "bg-purple-700",
            onClick: () => navigate("/contact")
        },
        {
            icon: faShare,
            title: "COMPARTIR",
            description: "¡Compartí Atenea con tus amigos!",
            color: "bg-blue-700",
            onClick: () => {
                // Only works on mobile
                navigator.share({
                    title: "Atenea",
                    text: "¡Descargá apuntes gratis!",
                    url: "https://atenea.lyt.ar"
                });
                // In web, possible to use navigator.clipboard.writeText("https://atenea.lyt.ar")
                
            }
        }
    ];
    return (
        <div className="flex flex-col justify-center items-center h-full space-y-4 w-full">
            <div className="bg-slate-800 p-5 rounded-lg text-center drop-shadow w-full">
                <div className="flex flex-row w-full pt-5 items-center gap-5">
                    <div className="flex flex-col items-center">
                        {/* Image */}
                        <img src="https://ui-avatars.com/api/?background=4d7c0f&color=fff&name=Luca+Mancuso&size=128" alt="profile" className="rounded-full" />
                    </div>
                    <div className="flex flex-col items-start justify-between w-full">
                        {/* Data and Buttons */}
                        <div className="flex flex-col items-start">
                            <h1 className="lg:text-5xl text-4xl font-bold text-white text-left">
                                Luca Mancuso
                            </h1>
                            <span className="text-gray-400 text-s font-mono mt-0">
                                @lmancuso
                            </span>
                        </div>
                        {/* Botones */}
                        <div className="flex w-full justify-end items-end">
                            <button className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded mt-5">
                                Editar perfil
                            </button>

                        </div>
                    </div>
                     
                </div>
            </div>
           {/* Carrusel Style Cards */}
            <div className="w-full">
                <div className="flex flex-row flex-nowrap overflow-x-scroll no-scrollbar">
                    {
                        cards_list.map(card => (
                            <div className="lg:basis-1/3 basis-[90%] flex-grow-0 shrink-0 p-1 ">
                                <div className={" rounded-lg text-center cursor-pointer p-4 " + card.color}
                                onClick={card.onClick}
                                >
                                    <FontAwesomeIcon icon={card.icon} className="text-2xl text-neutral-100" />
                                    <h1 className="text-neutral-100 text-lg font-bold">
                                        {card.title}</h1>
                                    <p className="text-neutral-100 text-sm text-center font-mono">
                                        {card.description}
                                    </p>

                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="bg-slate-800 p-3 rounded-lg text-center drop-shadow w-full">
                {
                    options_menu.map(option => (
                        <div className="w-full flex items-center p-4 hover:bg-slate-700 rounded-lg cursor-pointer transition duration-300 ease-in-out">
                            <div className="w-10 h-10 bg-blue-800/40 rounded-lg flex items-center justify-center mr-4">
                                <FontAwesomeIcon icon={option.icon} className="text-2xl text-white" />
                            </div>
                            <h1 className="text-white text-lg font-semibold">
                                {option.title}

                            </h1>
                        </div>
                    ))
                }

            </div>
        </div>
    );
}

export default Account;