import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { motion } from "framer-motion";
import { useMotionValue } from "framer-motion";
import { AnimatePresence } from "framer-motion";

const ContentCard = ({ content }) => {
    const [copied, setCopied] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const opacity = useMotionValue(0);

    const onCopy = (content) => {
        if (copied === content.id) return;
        // navigator.clipboard.writeText(content.url)
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }
    return (
        <div key={content.id} className="bg-slate-800 p-5 rounded-lg group">
            <h3 className="text-xl font-bold text-white">
                {content.title}
            </h3>
            <p className="text-gray-400 text-s font-mono mt-2">
                {content.description}
            </p>
            <div className="flex justify-end mt-5">
                <button 
                    className={" font-bold py-2 px-4 rounded align-middle" + (copied ? " text-green-500" : " text-gray-100 hover:text-neutral-500 opacity-0  group-hover:opacity-100 transition-opacity duration-200 ease-in-out"
                    )}
                    onClick={ () => onCopy(content) }>
                    {
                        copied ? 
                        <FontAwesomeIcon icon={faCircleCheck} />
                        :
                        <FontAwesomeIcon icon={faCopy} />
                    }

                    
                </button>

                <a className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                href={content.url}
                target="_blank"
                >Ver Contenido</a>
            </div>
 
            
        </div>
        
    );
}

export default ContentCard;