import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

const BottomNav = () => {
    const navigate = useNavigate();
    return (
        <div className="fixed bottom-2 left-0 w-full flex items-center justify-center p-1">
            <div className="lg:w-1/4 w-full bg-neutral-950/80 backdrop-blur-sm text-slate-100 text-lg rounded-3xl py-3 mx-2"
              >
                <div className="flex items-center justify-around text-sm font-light">
                    
                    <div className="hover:text-primary cursor-pointer text-center"
                        onClick={() => navigate("/")}
                    >
                        <FontAwesomeIcon icon={faHome} className="text-2xl" />
                        <p className="pt-1"
                        >Inicio</p>
                    </div>
                    <div className="hover:text-primary cursor-pointer text-center"
                        onClick={() => document.getElementById("search-bar").focus()}
                    >
                        <FontAwesomeIcon icon={faSearch} className="text-2xl" />
                        <p className="pt-1"
                        >Buscar</p>
                    </div>
                    <div className="hover:text-primary cursor-pointer text-center"
                        onClick={() => navigate("/account")}
                    >
                        <FontAwesomeIcon icon={faUser} className="text-2xl" />
                        <p className="pt-1"
                        >Cuenta</p>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default BottomNav;