import React, { useState, useEffect, Suspense } from "react";

// Importamos la función search
// import { getSubjects } from "../utils/actions";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { motion } from "framer-motion";

import Subjects from "../components/Subjects";
import InstallModal from "../components/InstallModal";

const Home = () => {
    const [subjects, setSubjects] = useState([]); // Inicializamos el estado de resultados
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState(""); // Inicializamos el estado de la consulta

    const [error, setError] = useState([]);

    const onChange = async (e) => {
        // Filtar resultados
        setQuery(e.target.value);
    }

    const getSubjects = async () => {
        console.log("Fetching...");
        let url = `https://api.atenea.lyt.ar/api/subjects?page=${page}`;
        if (query !== "") url += `&search=${query}`;
        const data = await fetch(url).then(
            response => {
                if (response.ok) {
                    return response.json();
                }
                throw response;
            }
        ).catch(error => {
            setError(error);
        });

        return data.subjects;
    }

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollTop = window.scrollY;
    //         const scrollHeight = document.documentElement.scrollHeight;
    //         const buffer = 100;

    //         if (scrollTop + buffer >= scrollHeight && hasMore) {
    //             setIsLoading(true);
    //             getSubjects().then((newSubjects) => {
    //                 setSubjects([...subjects, ...newSubjects]);
    //                 setIsLoading(false);
    //                 setHasMore(newSubjects.length > 0);
    //             });
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, [subjects, hasMore]);

    useEffect(() => {
        setIsLoading(true);
        const data = getSubjects().then(
            data => {
                setIsLoading(false);
                setSubjects(data);
            }
        ).catch(newError => console.log(newError));
    }, [query]);


    return (
        <>
            <motion.div
                initial={{ y: -250 }}
                animate={{ y: 0 }}
                transition={{ type: "spring", stiffness: 260, damping: 20 }}
                className="flex justify-center items-center flex-col mb-4 z-10">
                {/* <h1 className="text-6xl lg:text-8xl text-center font-bold text-neutral-950"
            >atenea</h1>
            <h2 className="text-md lg:text-xl text-center font-mono text-white ">
                Todos tus <span className=" text-orange-600  italic rounded-sm p-1">apuntes</span> en un solo lugar.
            </h2> */}
                <div className="flex items-center gap-3">
                    <img src="./logo512.png" alt="logo" className="w-20 h-20 lg:w-24 lg:h-24" />
                    <div className="flex flex-col">
                        <h1 className="text-6xl lg:text-8xl font-bold  italic text-neutral-950">atenea</h1>
                        <p className="text-neutral-500 text-md lg:text-xl">
                            Todos tus <span className="text-primary italic">apuntes</span> en
                            un solo lugar.
                        </p>
                    </div>
                </div>
                <InstallModal />


                    <div className="bg-inherit text-neutral-500 text-lg border-2 border-neutral-300 rounded-2xl my-5 px-5 py-2 lg:mx-12 lg:w-2/3 w-full mx-10 hover:scale-105 group-focus:scale-105 transition duration-300 ease-in-out shadow outline-none group-focus:ring-2 group-focus:ring-orange-500 flex items-center group">
                        <FontAwesomeIcon icon={faSearch} />
                        <input className=" bg-inherit ml-2 w-full outline-none ring-0"
                            id="search-bar"
                            type="text" placeholder="Buscar materia..." onChange={(e) => onChange(e)} />
                            
                    </div>


            </motion.div>
            {
                !isLoading &&
                <motion.div
                    initial={{ opacity: 0, y: 100, }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ type: "spring", stiffness: 260, damping: 20, delay: 0.5 }}

                >
                    <Subjects subjects={subjects} isLoading={false} />
                </motion.div>
            }


        </>
    );
};

export default Home;