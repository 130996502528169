import React from "react";
import PdfViewer from "../components/PdfViewer";

const Content = () => {
    return (
        <div>
            <h1>Contenido</h1>
            <PdfViewer url='https://api.atenea.lyt.ar/api/content/Practica0_Preliminares.pdf/' />
        </div>
    );
}

export default Content;