import React from "react";

const Login = () => {
  return (
    <div className="flex lg:items-center justify-center h-screen bg-neutral-50">
      <div className="flex flex-col gap-5 lg:w-1/4">
      <div className="h-1/6 block lg:hidden"></div>
        <div className="flex items-center gap-3">
          <img src="./logo512.png" alt="logo" className="w-16 h-16" />
          <div className="flex flex-col">
            <h1 className="text-5xl font-bold italic">atenea</h1>
            <p className="text-neutral-500 text-md">
              Todos tus <span className="text-primary italic">apuntes</span> en
              un solo lugar.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1"></div>
        <form className="flex flex-col gap-10" action="/">
          <div className="flex flex-col gap-3">
            <input
              type="text"
              placeholder="jdoe@itba.edu.ar"
              className="px-4 py-2 bg-inherit border border-neutral-300 rounded-lg shadow-sm focus:ring-1 focus:ring-neutral-950 focus:border-transparent focus:outline-none"
            />

            <input
              type="password"
              placeholder="•••••••••••"
              className="px-4 py-2 bg-inherit border border-neutral-300 rounded-lg shadow-sm focus:ring-1 focus:ring-neutral-950 focus:border-transparent focus:outline-none"
            />
            <div className="flex items-center justify-end">
              <a href="/" className="text-neutral-500 hover:text-primary">
                Restablecer contraseña
              </a>
            </div>
          </div>
          {/* Restablecer contraseña */}
          <div className="flex flex-col gap-3">
            <button
              type="submit"
              className="bg-primary text-neutral-50 font-bold p-2 rounded-xl hover:bg-neutral-50 hover:text-primary hover:border-primary border-2 border-primary"
            >
              Iniciar sesión
            </button>
          </div>
        </form>
        <div className="flex gap-3 items-center justify-center">  
          <p className="text-neutral-500">
            ¿Primera vez en <span className="italic font-medium">atenea</span>?
          </p>
          <a href="/signup" className="text-primary hover:underline">
            Crea una cuenta
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
