import React, { useState, useEffect } from 'react';

const PdfViewer = ({url}) => {
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    // Función para obtener el PDF de la API
    const fetchPdf = async () => {
      try {
        const response = await fetch(url);
        const pdfBlob = await response.blob();
        const pdfObjectUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfObjectUrl);
      } catch (error) {
        console.error('Error al obtener el PDF:', error);
      }
    };

    fetchPdf();

    // Limpiar el objeto URL cuando el componente se desmonta
    return () => {
      URL.revokeObjectURL(pdfUrl);
    };
  }, []);

  return (
    <div>
      <h1>Visor de PDF</h1>
      {pdfUrl && (
        <iframe
          title="PDF Viewer"
          src={pdfUrl}
          className='w-full h-screen'
        />
      )}
    </div>
  );
};

export default PdfViewer;
