import React from "react";
import { useNavigate } from "react-router-dom";
import SubjectCard from "./SubjectCard";


const Subjects = ({ subjects, isLoading }) => {

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {subjects.map((subject, index) => {
                    return <SubjectCard subject={subject} index={index} loading={isLoading} />
                })}
            </div>
        </div>
    );
    }

export default Subjects;