import React from "react";
import { useNavigate } from "react-router-dom";


const SubjectCard = ({ subject, index, loading}) => {
    const navigate = useNavigate();
    if (loading) {
        return (
            <div key={index} className="bg-blue-800/40 p-5 rounded-md h-24">
                {/* code */}
                <div className="h-4 bg-gray-400/40 rounded w-10 animate-pulse"></div>
                {/* tittle */}
                <div className="h-5 bg-neutral-100/40 rounded w-full mt-3 animate-pulse"></div>
            </div>
        );
    }
    return (
        <div key={index} className={`bg-inherit hover:bg-primary border-2 border-primary p-5 rounded-2xl cursor-pointer group hover:scale-[1.025] transition duration-300 ease-in-out`}
        onClick={ () => navigate(`/subject/${subject.code}`) } 
        >
            <p className="text-neutral-700 text-md font-semibold"> 
                {subject.code}
            </p>
            <h1 className="text-lg font-bold text-primary group-hover:text-neutral-50">
                {subject.name}
            </h1>
        </div>
    );
}

export default SubjectCard;