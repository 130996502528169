import React from "react";
import Navbar from "./Navbar";
import BottomNav from "./BottomNav";

import { motion } from "framer-motion";

const Layout = ({ children }) => {
    return (
        <>
        <main className=" text-white p-5 h-full min-h-screen">
        {/* <Navbar /> */}
            <div className="container mx-auto">
                {children}
            </div>
            {/* Footer para tapar el bottom nav */}
            <div className="h-20">
                    
            </div>
        
            <motion.div
            initial={{ opacity: 0, }}
            animate={{ opacity: 1 }}
            transition={{ type: "spring", stiffness: 260, damping: 20, delay: 0.8 }}
            >
                <BottomNav />
            </motion.div>
        </main>
        </>
    );
}

export default Layout;