import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import CareerBadge from "../components/CareerBadge";
import ContentCard from "../components/ContentCard";

import { motion, useMotionValue } from "framer-motion";


const Subject = () => {
    const { code } = useParams();
    const navigate = useNavigate();
    const [subject, setSubject] = useState({});
    const [contentsByType, setContentsByType] = useState([]);
    const [copied, setCopied] = useState(0);
    const [empty, setEmpty] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const [isTransitioning, setIsTransitioning] = useState(false);
    // const opacity = useMotionValue(0);

    const getSubject = async (code) => {
        const data = await fetch(`https://api.atenea.lyt.ar/api/subjects/${code}/contents`)
        .then(response => response.json())
        .then(data => {
            setIsLoading(false);
            setSubject(data.subject);
            setContentsByType(data.contents);
            console.log(data);
            data.contents.map(content => {
                if (content.contents.length > 0) {
                    setEmpty(false);
                }
            });

            return data.subject;
        });
        return data;
    }
    useEffect( () => {
        getSubject(code);
    }, []);

    const TYPE_NAMES = [
        "Guías de Ejercicios",
        "Guías de Ejercicios Resueltas",
        "Apuntes",
        "Parciales",
        "Finales",
    ]

    return (
        <div className="h-full my-5"
        >
            <button className="bg-blue-900/50 hover:bg-blue-500/50 text-white font-bold py-2 px-4 rounded mt-2 mb-6 hover:scale-105 transition duration-300 ease-in-out lg:block hidden"
            onClick={ () => navigate(-1) }
            >
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                Volver</button>
            <div className="flex justify-center flex-col bg-slate-800 rounded-lg p-4">
                
                {
                    isLoading ?
                    <div className="flex">
                        <div className="animate-pulse bg-slate-700 w-20 h-10 rounded-lg mb-5 ml-4 mt-3"></div>
                        <div className="animate-pulse bg-slate-700 w-72 h-10 rounded-lg mb-5 ml-4 mt-3"></div>
                        

                    </div>
                    : <h1 className="text-4xl font-bold mb-5 ml-4 mt-3 text-white">
                            {subject.code} - {subject.name}
                        </h1>
                }
                {/* <p className="text-gray-400 text-s font-mono mt-2 mx-5 text-justify"
                >{subject.description}</p> */}
                <p className="text-gray-400 text-s font-bold mt-2 ml-4">Carreras en las que está:</p>
 
                <ul className="flex flex-grow gap-1 text-gray-400 text-sm font-mono mt-2 ml-5 overflow-scroll no-scrollbar">
                    {
                        isLoading ? 
                        Array.from({ length: 5 }).map((_, index) => (
                            <li key={index} className="flex items-center gap-1">
                                <CareerBadge isLoanding={true} />
                            </li>
                        ))
                        : subject.careers.map(career => (
                        
                            <li key={career.id} className="flex items-center gap-1">
                                <CareerBadge career={career} />
                            </li>
                        ))
                    }
                    
                </ul>


                
            </div>
            {/* linea que separa */}
            <div className="flex-grow border-t border-neutral-600 my-5"></div>
            {
                empty ? 
                <>
                    <div className="flex flex-col items-center bg-slate-800 p-5 rounded-lg">
                        <h1 className="lg:text-4xl font-bold text-white text-center text-2xl">
                            Ops, parece que no hay apuntes....</h1>
                        <p className="text-xl text-gray-400 text-center text-s font-bold font-mono my-3">
                            ¡Sé el primero en subir un apunte para esta materia! <br />
                            Contactate con nosotros para subir tu apunte.
                            </p>
                        <div className="flex justify-end mt-5">
                            <a className="bg-orange-500 hover:bg-orange-700 text-2xl text-white font-bold py-2 px-6 rounded hover:scale-105 transition duration-300 ease-in-out"
                            href="/contact"
                            >Subir Apunte</a>
                        </div>
                    </div>
                </>
                    : null
            }
            {
                contentsByType.map((type,index) => (
                    
                        type.contents.length > 0 ? 
                        <>
                            <h1 key={index} className="text-2xl font-bold mt-5 mb-1 ml-1 text-white"
                            >{TYPE_NAMES[index]}</h1>
                            <div className="flex-grow border-t border-neutral-600/50 mb-5"></div>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {type.contents.map(content => {
                                    return <ContentCard content={content} key={content.id} />

                                })}
                            </div>
                        </>
                        : null
                ))
            }
        </div>
    );
}

export default Subject;

