import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// ROUTES
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Subject from "./pages/Subject";
import Account from "./pages/Account";
import Contact from "./pages/Contact";
import Content from "./pages/Content";
import Donate from "./pages/Donate";

import ProtectedRoutes from "./ProtectedRoutes";
import Login from "./pages/Login";
import SingUp from "./pages/Signup";

const Index = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login/" element={<Login />} />
        <Route path="/signup/" element={<SingUp />} /> 
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/subject/:code" element={<Subject />} />
          <Route path="/account/" element={<Account />} />
          <Route path="/contact/" element={<Contact />} />
          <Route path="/content/" element={<Content />} />
          <Route path="/donate/" element={<Donate />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
