import React, { useState, useEffect } from 'react';

function InstallModal() {
  const [showModal, setShowModal] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    // Manejar el evento beforeinstallprompt
    const handleBeforeInstallPrompt = (event) => {
      // Evitar que el navegador muestre automáticamente el banner de instalación
      event.preventDefault();
      // Guardar el evento para usarlo más tarde
      setDeferredPrompt(event);
      // Mostrar el modal de instalación
      setShowModal(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstall = () => {
    // Ocultar el modal
    setShowModal(false);
    // Mostrar el banner de instalación utilizando el evento guardado
    deferredPrompt.prompt();
    // Esperar a que el usuario interactúe con el banner de instalación
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('Usuario aceptó instalar la PWA');
      } else {
        console.log('Usuario canceló la instalación de la PWA');
      }
      // Limpiar el evento después de que se haya resuelto
      setDeferredPrompt(null);
    });
  };

  return (
    <div>
      {showModal && (
        <div className="modal fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="modal-content bg-slate-900 p-4 rounded-lg shadow-lg text-center">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <h2 className="text-2xl font-bold"
            >Instalar la aplicación</h2>
            <p>¡Agrega esta aplicación a tu pantalla de inicio para acceder fácilmente en el futuro!</p>
            <button className='bg-orange-500 text-white px-4 py-2 rounded-lg mt-4'
            onClick={handleInstall}>Instalar</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default InstallModal;
