import React from "react";

const CareerBadge = ({ career, isLoanding }) => {
    const careerColors = {
        "S" : "bg-rose-800 text-rose-300",
        "I" : "bg-yellow-800 text-yellow-300",
        "BIO" : "bg-emerald-800 text-emerald-300",
        "K" : "bg-purple-800 text-purple-300",
        "M" : "bg-orange-800 text-orange-300",
        "P" : "bg-red-800 text-red-300",
        "Q" : "bg-green-800 text-green-300",
        "N" : "bg-blue-800 text-blue-300",
    } 
    if (isLoanding) {
        const randomColor = Math.floor(Math.random() * 8);
        return (
            <div className={`text-xs font-medium align-middle me-2 w-40 h-6 rounded animate-pulse ${careerColors[Object.keys(careerColors)[randomColor]]}`}></div>
        )
    }
    return (
        <span className={`text-xs font-medium align-middle me-2 px-2.5 py-1 rounded ${careerColors[career.code]}`}>{career.name}</span>
    )
}

export default CareerBadge;